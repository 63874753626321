<template>
  <v-card min-height="100vh" flat color="transparent" class="xw-full">
    <v-card-title class="xflex xflex-row xjustify-between xitems-center">
      <Filters
        :search="search"
        :value="selectedYearMonth"
        @change="navigate" 
        @project-users-updated="fetch(selectedYearMonth)"
        type="monthly"
      >
      </Filters>
    </v-card-title>
    <v-card-text>
      <UsersTable
        :loading="loading"
        :loading-more="loading_more"
        :items="item ? item.summary : []"
      >
      </UsersTable>
      <!-- <pre>{{ item }}</pre> -->
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters, mapMutations } from "vuex";
import Filters from "../components/Filters.vue";
import UsersTable from "../components/UsersTable.vue";
export default {
  data() {
    return {
      tab: "projects",
      loading: false,
      loading_more: false,
      item: null,
      search: null,
      selectedYearMonth: moment().format("YYYY-MM"),
      next_page_url: null,
    };
  },
  watch: {
    "$route.params.year_month": {
      handler: function (val) {
        this.selectedYearMonth = val;
        val && this.fetch(val);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user"]), 
    projectId() {
      return this.$route.params.project_id || null;
    },
  },
  methods: {
    ...mapMutations("projects", ["storeProject"]),
    navigate(val) {
      this.$router
        .push({
          name: "app-project-monthly",
          params: { year_month: val, project_id: this.projectId },
        })
        .catch(() => {});
    },
    fetch(year_month) {
      this.loading = true;
      this.$axios
        .get(`api/project-users/${this.projectId}/monthly/${year_month}`)
        .then(({ data }) => {
          this.item = data;
          this.storeProject(data);
          this.$event.$emit(
            "project-count",
            this.item ? this.item.summary.length : 0
          );
        })
        .finally(() => (this.loading = false));
    },
  },
  components: { Filters, UsersTable },
};
</script>

<style lang="scss" scoped></style>
